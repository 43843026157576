@import '../../../styles/colors.scss';

.close-preview-button {
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: transparent;
  border: none;
  z-index: 6000;
  font-size: 1em;
  color: #222;
}

.show-correct-answers-button {
  display: flex;
  align-items: center;
  margin-left: 1em;
  background-color: #05698C;
  border: 1px solid #8B8B8B;
  border-radius: 5px;
  font-size: 14px;
  padding: 0.75em;
  z-index: 6000;
  font-size: 1em;
  color: #FFF;

  svg {
    margin-left: 0.75em;
  }
}

.page-item.active .page-link {
  background-color: $orange;
  outline: none;
  border: none;
  color: black;
}

.pagination-lg .page-item:first-child .page-link,
.pagination-lg .page-item:last-child .page-link {
  border-radius: 0;
}

.page-link {
  border-radius: 0;
  color: $black;
  outline: none;
  &:hover {
    text-decoration: none;
    color: $darkblue;
  }
}

.pagination {
  margin: 0 auto;
  overflow: auto;
}

.pagination-btn {
  outline: none;
  &:active {
    background-color: $white;
    outline: none;  
  }
}

.pagination-ellipsis {
  text-align: center;
  .page-link {
    padding-top: 15%;
  }
}

.preview {
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  .main-nav-container {
    border-bottom: 8px solid $backgroundGray;
  }
}

.pagination-buttons-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  overflow-y: hidden;
}
