@import '../../../styles/colors.scss';

.hidden-label {
  display: none;
}

.link-button {
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 1em;
  color: $darkblue;
  padding: 0;
  line-height: 1.15;
  &:focus, &:active, &:hover {
    outline: none;
    color: $black;
    text-decoration: underline;
  }
}

.flex-form {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
}

.login-page-header {
  margin: 1em 0;
  font-size: 1.6em;
  font-weight: bold;
}

.login-page-h2 {
  margin: 1.5em 0 0.5em;
  font-size: 1.4em;
  font-weight: bolder;
}

.login-page-button {
  padding: 5px 12px;
  width: 100%;
  min-height: 50px;
  background-color: $slateblue;
  border: 2px solid $slateblue;
  color: $white;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 0.5em;
  &:hover, &:focus {
    background: $lightblue;
    border-color: $slateblue;
    color: $black;
    text-decoration: none;
  }
}

.login-button {
  margin-right: 2em;
}