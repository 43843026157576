@import '../../styles/colors.scss';

.free-layout-page-content {
  position: relative;
  height: 100%;
  width: 100%;
}

.full-image-input-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  text-align: center; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image-page-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hide-upload-image-button {
  display: none;
}

.image-left-image-input-container {
  position: absolute;
  top: 8%;
  left: 5%;
  width: 42.5%;
  height: 85%;
  padding: 0;
  text-align: center;
  &:hover {
    border: 1px dotted $darkGray;
  }
}

.image-left-page-content {
  margin: 0 auto;
}

.image-right-image-input-container {
  position: absolute;
  top: 8%;
  left: 52.5%;
  width: 42.5%;
  height: 85%;
  max-height: 100%;
  padding: 0;
  text-align: center;
  &:hover {
    border: 1px dotted gray;
  }
}

.image-right-page-content {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: center;
  align-items: center;
}

.image-top-image-input-container {
  height: 66%;
  width: 64%;
  position: absolute;
  top: 4%;
  left: 18%;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 600px) {
    margin-left: 10px;
    overflow-y: scroll;
  }
  &:hover {
    border:  1px dotted gray;
  }
}

.image-top-page-content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
}

.create-page-content {
  position: relative;
  width: 100%;
  height: calc(63vw * 0.625);
}

.create-page-content-vertical-layout {
  height: calc(63vw * 1.414) !important;
}

.empty-create-page-content {
  position: relative;
  background-color: $white;
  width: 100%;
  height: calc(63vw * 0.625);
} 

.new-page-container {
  margin: 0;
  width: 63vw;
}

.page-container {
  height: 100%;
  width: 100%;
}

.remove-image-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transition: .5s ease;
  opacity: 0;
  width: 120px;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  border-radius: 5px;
  background-color: $white;
  color: black;
  border: none;
  z-index: 100;
}

.uploaded-image-container {
  position: relative;
  &:hover {
    opacity: 1;
    }
  &:hover img {
    opacity: 0.3;
  }
  &:hover button, &:hover svg {
    opacity: 0.8;
  }
}

.uploaded-image-content {
  max-width: 100%;
  object-fit: contain;
  transition: .5s ease;
  opacity: 1;
  z-index: 1;
}

.image-page-content {
  width: 100%;
  height: 100%;
}
