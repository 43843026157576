@import '../../styles/colors.scss';

.add-page-button {
  padding: 2vw 0.5vw;
  width: 100%;
  background-color: $slateblue;
  border: 2px solid $slateblue;
  color: $white;
  border-radius: 0px;
  margin-bottom: 10%;
  margin-top: 10%;
  font-size: 2vw;
  transition-duration: 0.1s;
  @media (min-width: 576px) {
    width: 90%;
  }
  &:hover {
    color: $black;
    background-color: $white;
  }
}

.add-page-icon {
  background-color: transparent;
  margin-right: 4px;
  font-size: 1em;
  @media (min-width: 768px){
    margin-right: 10px;
  }
}

.add-page-icon-container {
  background-color: transparent;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  height: fit-content;
  text-align: center;
  @media (min-width: 576px) {
    width: 100%;
  }
}

.delete-page-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background: $white;
  padding: 0px;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 100%;
  z-index: 4000;
  width: 1.5rem;
  height: 1.5rem;
  overflow: visible;
  &:focus, 
  &:hover {
    color: $darkGray;
  }
}

.delete-page-icon {
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.page-list {
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 5px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  align-items: center;
  position: relative;
  @media (min-width: 576px) {
    padding: 0px 2px;
  }
}

.page-list-item {
  position: relative;
  width: 14vw;
  min-height: 8.75vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 2px solid gray;
  margin: 0.5vw;
  padding: 0;
  margin-bottom: 1vw;
  text-decoration: none;
  box-shadow: 1px 1px 2px #555;
  box-sizing: border-box;
  &:focus {
    outline: 2px solid $black;
    padding: 3px;
  }
}

.page-list-item-vertical-layout {
  width: calc(14vw * 0.7072);
  height: 14vw;
  min-height: 14vw;
}

.active-page-list-item {
  outline: 4px solid $black;
  border: none;
  padding: 0;
}

.pages-sider-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  position: relative;
  width: 15vw;
  margin-right: 2%;
  padding-top: 2px;
  min-height: calc(63vw * 0.625 + 6vw);
  height: fit-content;
  max-height: 1024px;
}

.sider-thumbnail-button {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
}