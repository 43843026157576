@import '../../../styles/colors.scss';

.links-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  margin-left: 18px;
  margin-right: 18px;
  @media (max-width: 600px) {
    margin: 0;
  }
}

.logout-button {
  padding: 0;
  margin: 0;
  outline: none;
  background: transparent;
  font-family: 'Lato', sans-serif;
  font-size: 0.8em;
  font-weight: normal;
  border: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  border: 3px solid transparent;
  @media (min-width: 768px) {
    font-size: 1em;
  }
  &:focus {
    border: none;
    outline: none;
  }
  &:hover {
    color: $darkblue;
  }
}

.logout-link {
  font-size: 14px;
  padding: 5px;
  border: none;
  @media (min-width: 768px) {
    padding: 0.2rem;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    padding: 0.3rem 0.5rem;
  }

}

.main-nav-container {
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-top: 8px solid $orange;
  background-color: #ffffff;
}

.nav {
  color: black;
  width: 100%;
}

.navbar {
  padding: 0;
  background-color: $white !important;
  width: 100%;
}

.nav-item-list {
  display: block;
  @media (min-width: 768px) {
    display: flex;
  }
}

.navbar-light {
  background-color: $white;
}

.navbar-light 
.navbar-toggler {
  border-color: rgba(0, 0, 0, 0.5);
  margin: 5px;
}


.navbar-expand-md
.navbar-nav 
.nav-link {
  font-size: 14px;
  @media (min-width: 768px) {
    padding: 0.2rem;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    padding: 0.3rem 0.5rem;
  }
}

.navbar-light 
.navbar-nav 
.nav-link {
  color: $black;
  text-decoration: none;
  .active {
    border: 2px solid $black;
  }
  &:hover {
    color: $darkblue;
  }
}

.navbar-nav {
  display: block;
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}

.nav-button {
  padding: 0;
  margin: 0;
  outline: none;
  background: transparent;
  font-family: 'Lato', sans-serif;
  font-size: 0.8em;
  font-weight: normal;
  border: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  border: 3px solid transparent;
  @media (min-width: 768px) {
    font-size: 1em;
  }
  &:focus, &:active {
    border: 3px solid $black;
    border-bottom: none;
    border-top: none;
    outline: none;
    background-color: #f0f0f0;
  }
}

.nav-link-container {
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 0 0.2rem;
  }
  @media (min-width: 1024px) {
    padding: 0rem 0.5rem;
  }
}
