@import '../../../styles/colors.scss';

.category-list-button {
  border: none;
  background: none;
  cursor: pointer;
  margin: 5px 5px 5px 0px;
  &:focus {
    outline: none;
    border: 1px solid $black;
  }
}

.categories-list {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  padding-left: 0;
  margin: 5px 0;
  @media (max-width: 600px) {
    max-height: 100%;
  }
}

.search-buttons-container,
.text-input-container {
  margin-top: 20px;
}

.search-input-and-buttons-container {
  margin-top: 0;
}

.return-to-search-button {
  cursor: pointer;
  background-color: $buttonGray;
  width: 100px;
  height: 38px;
  padding: 5px 15px;
  margin-right: auto;
  border: 1px solid $backgroundGray;
  border-radius: 3px;
  &:focus {
    outline: 1px solid $black;
    outline-offset: -0.1cm;
  }
  &:hover {
    background-color: #fff;
  }
}

.spinner-content {
  text-align: center;
  padding-top: 100px;
  width: 100%;
  height: 100%;
  background-color: $white;
}

.spinner-icon {
  margin: 3% auto;
  font-size: 2em;
}

h3.search-results-header {
  margin: 15px auto;
  text-align: center;
}
