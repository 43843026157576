@import '../../styles/colors.scss';

.float-right-button {
  width: 100%;
  @media (min-width: 576px) {
    float: right; 
    width: 250px; 
    margin-left: 0;
  }
}

.qrcode-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
}

.share-buttons-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
}

.share-link {
  overflow-wrap: break-word;
  color: $darkblue;
}

.share-link-container {
  margin-top: 10px;
  h3 {
    margin-bottom: 10px;
  }
}

.share-settings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px auto;
}

.uppercase-text {
  text-transform: uppercase;
}

.visited-link {
  color: $black;;
}

.opike-copy-button {
  background: $opikeYellow;
  border-color: $opikeYellow;
  color: $opikeBlack;

  &:hover,
  &:focus,
  &:active {
    background: $opikeDarkYellow;
    border-color: $opikeYellow;
  }
}