// Muokattava sivu: 63vw
// Sivupainike: 9.9008vw
// Ratio: 0.1572

$ratio: 0.1572;
$block_image_padding: 0.7vw * $ratio;
$block_image_padding_half: calc($block_image_padding * $ratio / 2);

.page-thumb-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .page-thumb-block-image-row {
    padding-top: $block_image_padding;
    padding-right: $block_image_padding_half;
    padding-bottom: $block_image_padding_half;
    padding-left: $block_image_padding_half;

    .thumb-block-image {
      display: flex;
      flex-direction: row;
      width: auto;
      margin: 0 $block_image_padding_half;
    }
  }

  .page-thumb-block-text-row {
    padding-top: $block_image_padding_half;
    padding-right: $block_image_padding;
    padding-bottom: $block_image_padding;
    padding-left: $block_image_padding;
    flex-direction: column;

    p {
      width: 100%;
      margin: 0;
    }
  }

  div {
    display: flex;
    flex-direction: row;
  	font-size: calc(1.2em * 0.13);
    overflow: hidden;

    h1 {
  	  font-size: 0.6vw;
      white-space: nowrap;
      margin-top: 0.25vw;
    }

    h2 {
      font-size: 0.5vw;
      white-space: nowrap;
      margin-top: 0.2vw;
    }
  }

  div:first-of-type {
    justify-content: space-between;
    width: 100%;
  }

}

.page-thumb-question-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  // .page-thumb-block-image-row {
  //   padding-top: $block_image_padding;
  //   padding-right: $block_image_padding_half;
  //   padding-bottom: $block_image_padding_half;
  //   padding-left: $block_image_padding_half;

  //   .thumb-block-image {
  //     display: flex;
  //     flex-direction: row;
  //     width: auto;
  //     margin: 0 $block_image_padding_half;
  //   }
  // }

  .page-thumb-question {
    padding-top: $block_image_padding_half;
    padding-right: $block_image_padding;
    padding-bottom: $block_image_padding;
    padding-left: $block_image_padding;
    display: flex;
    flex-direction: column;

    p {
      width: 100%;
      margin: 0;
      white-space: nowrap;
    }
  }

  .page-thumb-answer {
    display: flex;
    flex-direction: column;
    margin: 0.5em;
    padding: 0.5em;
    border: 0.25px solid rgb(80, 80, 80);
    border-radius: 1px;
    width: 100%;
    max-width: calc(100% - 1em);

    p {
      margin: 0;
      white-space: nowrap;
    }
  }

  div {
    display: flex;
    flex-direction: row;
  	font-size: calc(1.2em * 0.13);
    overflow: hidden;

    h1 {
  	  font-size: 0.6vw;
      white-space: nowrap;
      margin-top: 0.25vw;
    }

    h2 {
      font-size: 0.5vw;
      white-space: nowrap;
      margin-top: 0.2vw;
    }
  }

  div:first-of-type {
    justify-content: space-between;
    width: 100%;
  }

}
