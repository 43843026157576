@import '../../styles/colors.scss';

.audio-message-error-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}

.audio-recording-content {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-around;
  height: 300px;
  max-width: 300px;
  margin: 0 auto;
}

.audio-recording-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.audio-description-text {
  text-align: center !important;
  font-size: 1em;
  margin-top: 1em;
  p {
    text-align: center !important;
  }
}

.audio-control-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.audio-timer {
  margin:10px;
  font-size: 1.3em;
  align-self: center;
}

.IE-close-button {
  padding: 10px 20px;
  margin-top: 1em;
}

.IE-info-container {
  margin: 4em auto 1em;
  text-align: center;
}

.IE-info-text {
  font-size: 1.2em;
  line-height: 1.3em;
}

.pulse-recording {
  margin:10px;
  display: block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #ff0000;
  box-shadow: 0 0 0 rgba(255, 0, 0, 0.4);
  animation: pulse 2s infinite;
  align-self: center;
}
.pulse-done {
  margin:10px;
  display: block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #888888;
  box-shadow: 0 0 0 rgba(134, 134, 134, 0.4);
  align-self: center;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(167, 70, 70, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.record-audio-button {
  border-radius: 50%;
  border: 3px solid $black;
  background-color: $lightblue;
  padding: 10px 15px;
}