@import url('https://fonts.googleapis.com/css?family=Poppins');

.instructions {
  margin-right: 5%;
}

.instructions-header {
  font-family:  'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 0.9em;
  margin: 5px 0px;
}

.instructions p {
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 1.1em;
  line-height: normal;
  @media (min-width: 768px) {
    width: 150px;
  }
}

.feedback-content {
  margin-left: 0;
  width: 100%;
  max-width: 800px;
  @media (min-width: 576px) {
    padding: 0;
    margin: 0;
    width: 50%;
  }
  input {
    &:focus {
      outline: none;
    }
  }
}

.form-control:focus {
  border-color: black !important;
  border-width: 2px;
  outline: none !important;
  box-shadow: none !important;
}

.video-item {
  margin: 5px;
  border: 1px solid gray;
}

.video-list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    justify-content: center;
  }
}