@import '../../styles/colors.scss';

.error-message-content {
  position: relative;
  width: 80%;
  height: 50%;
  min-height: fit-content;
  margin: 40px auto;
  background-color: $white;
  text-align: center;
  padding-top: 2%;
}

.error-message-container {
  width: 100vw;
  height: 100vh;
}