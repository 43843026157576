@import '../../styles/colors.scss';

.play-button {
  height: 30px;
}

.play-icon {
  padding: 0;
}

.play-icon-button {
  position: absolute;
  bottom: 5%;
  left: 0%;
  margin: 0;
  transition: .5s ease;
  transform: translate(20%, -50%);
  padding: 1%;
  background-color: $white;
  color: black;
  border: none;
  border-radius: 10%;
  z-index: 6000;
}

.react-player {
  position: relative;
  z-index: 0;
}

.video-container {
  &:hover .move-video-handle,
  &:hover button,
  &:active .move-video-handle {
    opacity: 1;
    z-index: 6000;
  }
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.video-thumbnail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
