@import '../../../styles/colors.scss';

.preview-editor {
  .mce-content-body {
    height: 100%;
  }
}

.preview-page-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.preview-page {
  height: 100%;
  width: 100%;
  position: relative;
}

.preview-page-content {
  margin: 0 auto;
  background-color: $white;
  position: relative;
  margin-top: 0.5%;
  overflow: hidden;
}

.preview-image-container {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.preview-image {
  padding: 2%;
}

.preview-text-container {
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.preview-page-content div.textbox {
  border: none;
}

.add-margins {
  margin-top: 1%;
  margin-bottom: 1%;
}

.preview-page-content .question-wrapper.preview-remove-effects .mce-content-body {
  width: 15em;
}

.preview-answers-list-container {
  display: flex;
  flex-direction: column;
  max-width: 17em;

  div.preview-answer-container {
    display: flex;
    flex-direction: row;

    span.correct-answer-thumbs-up {
      position: absolute;
      right: -1.1em;
      padding-top: 0.45em;
      width: 1.1em;

      svg {
        width: 100%;
        height: auto;
      }
    }

    button.preview-answer-button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0.375em 0.5em;
      padding: 0.5em;
      border: 1px solid black;
      border-radius: 5px;
      color: black;
      width: 100%;
      max-width: calc(100% - 1em);
      outline-offset: 0;

      span p {
        margin: 0 !important;
        text-align: left;
      }
    }

    button.preview-answer-button:hover,
    button.preview-answer-button:focus {
      cursor: pointer;
      outline: 2px solid black;
    }

    // button.preview-answer-button.checked {
    //   border: 1px solid #0e59c6 !important;
    //   outline: 2px solid #0e59c6 !important;
    // }

    .answer-checked-icon,
    .answer-square-icon {
      display: flex;
      font-size: 1.25em;
      margin: 0 0 0 0.5em;
    }
  }

  div.preview-answer-container:hover {
    cursor: pointer;

    button.preview-answer-button {
      outline: 2px solid black;
    }
  }
}
