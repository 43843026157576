@import '../../../styles/colors.scss';

.question-guide-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 10px 0;

  img {
    width: 40%;
    max-width: 100px;
    height: auto;
    margin: auto;
  }
}
