@import '../../styles/colors.scss';

.opike-landing-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $opikeWhite;
  min-height: 100vh;

  & > * {
    display: flex;
  }

  .opike-top-bar {
    width: 100%;
    height: fit-content;
    background-color: $opikeYellow;
    padding: 1em;
    box-shadow: 0px 0px 10px #555;

    svg {
      width: 50px;
      height: auto;
      margin: 0 auto 0 0;
    }
  }

  .opike-content {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 3em auto;

    .opike-col {
      display: flex;
      flex-direction: column;
      text-align: left;
      min-width: 100px;
      max-width: 400px;
      padding: 1em;

      .opike-book-thumbnail {
        width: 200px;
        height: auto;
        margin: 0 auto;
      }

      h2 {
        margin: 0 !important;
      }

      p {
        margin: 1.5em 0;
      }

      .opike-button {
        border: 2px solid $opikeGreen;
        border-radius: 5px;
        background-color: $opikeGreen;
        color: $opikeWhite;
        outline: none;
        margin: 0 auto 0 0;
        padding: 1em;
        width: fit-content;
        min-width: 200px;
        box-shadow: 0px 1px 3px #555;
        transition-duration: 0.2s;
      }

      .opike-button:hover,
      .opike-button:focus,
      .opike-button:active {
        cursor: pointer;
        background-color: $opikeDarkGreen;
        border: 2px solid $opikeDarkGreen;
      }
    }
  }

}

@media only screen and (max-width: 640px) {
  .opike-landing-page {
    .opike-content {
      flex-direction: column;

      .opike-col {
        text-align: center;

        .opike-button {
          margin: 0 auto;
        }
      }
    }
  }
}