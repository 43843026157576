.choose-template-content {
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  @media (max-width: 600px) {
    padding: 10px;
  }
}

.choose-template-instructions-header {
  display: flex;
  flex-flow: row nowrap;
  text-align: center;
  justify-content: flex-start;
  margin-top: 50px;
  h2 {
    margin: 0;
    padding: 5px 10px 3px 0px;
  }
}

.choose-template-modal-container {
  width: 100%;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-flow: row nowrap;
}

.close-templates-button {
  background-color: transparent;
  border: 2px solid transparent;
  outline: none;
}

.template-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
}

.template-instructions-content {
  width: 17%;
  margin-right: 60px;
}

.left-template-column,
.right-template-column {
  max-width: 50%;
}