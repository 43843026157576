@import '../../styles/colors.scss';

.centered-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.dialogue-button {
  margin: 3% 2%;
  min-height: 50px;
  width: 100%;
  padding: 5px 12px;
  border-radius: 5px;
}


.danger-button {
  border: 2px solid $darkRed;
  background-color: $lightRed;
  outline: none;
  margin-left: 0%;
  transition-duration: 0.2s;
  &:hover,
  &:active,
  &:focus {
    background-color: $darkRed;
    border: 2px solid $darkRed;
    color: $white;
    outline: none;
  }
}
.gray-button {
  background-color: $white;
  border: 2px solid $backgroundGray;
  margin-right: 0%;
  transition-duration: 0.2s;
  &:hover {
    background-color: $buttonGray;
    border-color: $backgroundGray;
  }
}

.icon-button-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 425px) {
    justify-content: flex-start;
    padding: 5px 0px;
  }
}

.info-page-button {
  width: 100%;
  height: inherit;
  padding: 0.5em 1em;
  margin-top: 1em;
  @media (min-width: 786px) {
    width: 250px;
  }
}

.modal-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.margin-top-button {
  margin-top: 30px;
  margin-left: 0;
}


.select-button {
  border: 2px solid $slateblue;
  background-color: $slateblue;
  color: $white;
  outline: none;
  margin-left: 0%;
  transition-duration: 0.2s;
  &:hover,
  &:focus {
    background-color: $lightblue;
    color: $black;
    border: 2px solid $slateblue;
    outline: none;
    a {
      color: $white;
    }
  }
  a {
    &:hover, &:active {
      text-decoration: none;
    }
  }
}

.single-button {
  width: 100%;
  margin: 3% 0;
}

.toggle-instructions-button {
  font-size: 1.5vw;
  width: 100%;
  margin-top: 1px;
  border: 1px solid darkgray;
  background-color: $buttonGray;
  transition-duration: 0.2s;
  @media (min-width: 576px) {
    margin-top: 10px;
  }
  padding: 0.5em 1em;
  outline: none;
  margin-right: 1em;
  margin-top: 1em;
  &:hover {
    border: 1px solid $black;
    outline: 1px solid $black;
  }
}