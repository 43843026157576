@import '../../styles/colors.scss';

.draggable-image-content {
  position: relative;
}

.draggable-container,
.resizable-container,
.video-thumbnail-container {
  outline: none;
  &:hover .move-item-handle, 
  &:hover .cancel-icon,
  &:hover .delete-item-button,
  &:hover .edit-image-button,
  &:hover .resizable-handle,
  &:focus .move-item-handle, 
  &:focus .cancel-icon,
  &:focus .delete-item-button,
  &:focus .edit-image-button,
  &:focus .resizable-handle,
  &:active .move-item-handle, 
  &:active .cancel-icon,
  &:active .delete-item-button,
  &:active .resizable-handle,
  &:active .edit-image-button {
    opacity: 1;
    z-index: 5000;
    outline: none;
  }
  &:hover button {
    opacity: 1;
  }
}

.move-item-handle {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 100%;
  transition: .5s ease;
  transform: translate(-50%, 50%);
  opacity: 0;
  background-color: $white;
  padding: 0;
  border-radius: 5px;
}

.resizable-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  outline: none;
  &:hover, &:focus, &:active {
    outline: 1px dotted $darkGray;
    outline-offset: -1px;
  }
  // &:focus, &:active {
  //   outline: none;
  //   border: none;
  // }
}

