@import './colors.scss';
@import '~normalize.css'; 

 * {
  box-sizing: border-box;
  font-size: 1em;
 }

html {
  height: 100%;
  width: 100%;
  overflow: auto;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: $backgroundGray;
  color: black;
  line-height: 1.2;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}

button {
  cursor: pointer;
  background-color: $buttonGray;
  font-size: 1em;
  color: $black;
  &:focus {
    outline: 3px solid $black;
    outline-offset: -0.1cm;
  }
}

.close-btn {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  &:hover {
    color: $backgroundGray;
  }
}

form {
  margin-top: 20px;
}

h1,
h2,
a,
.link {
  font-family: 'Lato', sans-serif;
}

.h1-title {
  font-size: 2.2em;
}

.h2-title {
  font-size: 1.5em;
}



p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

a, 
.link {
  color: $darkblue;
  outline: none;
  text-decoration: none;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}

a, 
button {
  outline: none;
}

.align-content-left {
  text-align: left;
}

.edit-view {
  display: flex;
  flex-flow: row nowrap; 
  justify-content: space-evenly;
  width: 100vw;
  height: calc(63vw * 0.625 + 6vw + 8px);
  padding-top: 8px;
}

// Pystysivun A4-paperia mallintava korkeus + yläpalkki ja marginaali
.edit-view-vertical-layout {
  height: calc((63vw * 1.414) + 6vw + 8px) !important;
}

input,
textarea {
  outline: none;
}

label {
  font-weight: bold;
  font-size: 0.7em;
  margin-bottom: 12px;
}

input:-webkit-autofill {
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
}
input:-moz-autofill {
  -moz-text-fill-color: $black;
  -moz-box-shadow: 0 0 0px 1000px $white inset;
}
input:-o-autofill {
  -o-text-fill-color: $black;
  -o-box-shadow: 0 0 0px 1000px $white  inset;
}
input:-khtml-autofill {
  -khtml-text-fill-color: $black;
  -khtml-box-shadow: 0 0 0px 1000px $white  inset;
}

.input-label-class {
  font-size: 0.9em;
  margin-top: 10px;
  text-align: left;
}

.info-text-container {
  background-color: $white;
  width: 100%;
  padding: 0;
  h2 {
    padding: 1% 0;
  }
  .main-nav-container {
    border-bottom: 8px solid $backgroundGray;
  }
}

.info-text-content {
  width: 80%;
  margin: 1% auto;
  padding: 2%;
  padding-bottom: 10%;
}

.main-container {
  width: 100%;
  height: 100%;
}

.modal-text {
  font-size: 1.2em;
}

.info-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  overflow-y: auto;
}

.page-header {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5em;
  margin: 0;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.page-sub-header {
  text-transform: none;
  font-weight: 600;
  font-size: 1.2em;
  margin: 1em 0 0.5em 0;
}

.ReactModal__Overlay {
  z-index: 9999;
}

.ReactModalPortal > * {
  // When the modal is closed, overlay div has no css class
  // This selector should be overridden by the `&--after-open` class below
  opacity: 0;
}
.ReactModal__Overlay {
  transition: opacity 300ms ease-in-out;
  background: rgba(0, 0, 0, 0.15);
  &--after-open {
      opacity: 1;
  }
  &--before-close {
      opacity: 0;
  }
}

.form-check-input:checked {
  background-color: $darkblue;
  border-color: $darkblue;
}

.textarea {
  border: none;
}

.centered-page-container {
  background-color: $white;
  margin: 8px auto;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
}

.centered-page-content {
  margin: 2% 3% 4%;
  width: 90%;
  @media (min-width: 576px) {
    margin: 4% auto;
    width: 60%;
  }
}

.two-part-page-container {
  background-color: $white;
  margin: 8px 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 10px;
  @media (min-width: 576px) {
    width: 100%;
  }
}

.two-part-page-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2px;
  margin-left: 0;
  height: 100%;
  @media (min-width: 768px) {
    flex-flow: row nowrap;
    margin: 10px;
    padding: 10px;
    padding-top: 30px;
  }
}

#root {
  height: 100%;
  width: 100%;
}

.sider-container {
  margin: 0 1.25vw;
  width: 16vw;
  p {
    font-size: 0.7em;
    @media (min-width: 576px) {
      font-size: 0.9em;
    }
    @media (min-width: 1440px) {
      font-size: 1em;
    }
  }
}

.backend-response-text {
  min-height: 1.5em;
  font-size: 0.9em;
  margin: 0;
}

.red-text {
  color: $darkRed;
}

.success-text {
  color: $darkGreen;
}


.validation-error-text {
  color: $darkRed;
  font-size: 0.9em;
  margin: 0;
  margin-top: 4px;
  height: 2em;
}

.row-space-between-container {
  display: flex;
  flex-flow: row  nowrap;
  align-items: center;
  justify-content: space-between;
}