@import '../../../styles/colors.scss';

#dropdown-item-button {
  border: 1px solid gray;
  color: black;
  background-color: $buttonGray;
  &:hover {
    background-color: #fff;
  }
}

form {
  margin-top: 0;
}

.form-control {
  height: 100%;
  padding: 0.75rem;
}

.select-checkbox {
  margin-right: 5px;
}

.select-input-content {
  display: flex;
  justify-content: center;
  align-items: center;
}