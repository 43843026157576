@import '../../../styles/colors.scss';

.no-grid-template {
  width: 100%;
  height: 100%;
}

.page-number-container {
  width: fit-content;
  min-width: 30px;
  height: 2.5vw;
  line-height: normal;
  padding: 0;
  margin: 0;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  border-top-right-radius: 0.25em;

  span {
    padding: 0.25em;
  }
}

.page-number {
  color: black;
  font-weight: bold;
  font-size: 0.8em;
  @media (min-width: 1024px) {
    font-size: 1.5em;
  }

}

.thumbnail-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

.thumbnail-content {
  width: 100%;
  height: 8.75vw;
}

.thumbnail-content-vertical-layout {
  width: 100%;
  height: 100%;
}

.thumbnail-grid-2x2 {
  position: relative;
  height: 100%;
  width: 100%;
  background-image:
    linear-gradient(90deg, transparent 49.3%, $gridGray 49.4%, transparent 50%),
    linear-gradient(to bottom, transparent 49.3%, $gridGray 49.4%, transparent 50%);
  background-size: 100%, 100%;
}

.thumbnail-grid-3x2 {
  position: relative;
  height: 100%;
  width: 100%;
  background-image:
    linear-gradient(-90deg, transparent 33.2%, $gridGray 33.3%, transparent 34%),
    linear-gradient(-90deg, transparent 66.5%, $gridGray 66.6%, transparent 67%),
    linear-gradient(to top, transparent 49.3%, $gridGray 49.4%, transparent 50%);
  background-size: 100%, 100%;
}

.thumbnail-grid-4x3 {
  position: relative;
  height: 100%;
  width: 100%;
  background-image:
    linear-gradient(to right, transparent 24.3%, $gridGray 24.4%, transparent 25%),
    linear-gradient(to right, transparent 49.3%, $gridGray 49.4%, transparent 50%),
    linear-gradient(to right, transparent 74.3%, $gridGray 74.4%, transparent 75%),
    linear-gradient(to top, transparent 33.2%, $gridGray 33.3%, transparent 34%),
    linear-gradient(to top, transparent 66.5%, $gridGray 66.6%, transparent 67.3%),;
  background-size: 100%, 100%;
}


.thumbnail-image {
  height: auto;
  width: auto;
  max-height: 100%;
}

.thumbnail-image-content {
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.thumbnail-video-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  max-height: 100%;
  max-width: 100%;
}