@import '../../styles/colors.scss';

.add-book-button {
  padding: 5px 12px;
  border-radius: 5px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 1em;
  min-height: 50px;
  font-size: 0.9em;
  svg {
    margin-right: 10px;
  }
}

.create-icon {
  margin-right: 1vw;
}

.books-container {
  width: 100%;
}

.books-content {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.own-books-button-container {
  display: flex;
  flex-flow:  row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  @media (min-width: 576px) {
    flex-flow:  row nowrap;
    margin-top: 0;
  } 
}

.sort-books-buttons-container {
  display: flex;
  flex-flow: row nowrap;
}

.sort-books-button {
  background-color: $white;
  border: 1px solid $backgroundGray;
  padding: 5px 12px;
  min-height: 50px;
  border-radius: 5px;
  outline: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  margin-right: 1em;
  margin-top: 1em;
  font-size: 0.9em;
  @media (min-width: 576px) {
    margin-top: 0;
  }
  svg {
    margin-right: 10px;
  } 
  &:hover {
    border: 2px solid $black;
    padding: 4px 11px;
  }
}

.sort-active-button {
  background-color: $buttonGray;
}

.sort-books-icon {
  font-size: 1.7em;
  margin-right: 5px;
}