@import '../../styles/colors.scss';

.audio-button {
  padding: 5%;
  border: 1px solid gray;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.audio-icon {
  font-size: 1em;
  @media (min-width: 768px) {
    font-size: 2em;
  }
}

.audio-container {
  z-index: 1000;
  width: 6%;
  height: 8%;
}

.audio-player-button-container {
  position: absolute;
  top: 0;
  left: 0;
  &:active .delete-item-button,
  &:hover .delete-item-button,
  &:active .move-audio-button,
  &:hover .move-audio-button {
    opacity: 1;
  }
  &:hover .audio-buttons-container {
    display: initial;
  }
}

.audio-buttons-container {
  height: 11%;
  width: 3.25em;
  position: absolute;
  top: -26px;
  left: 0;
  display: none;
}

.delete-audio-button {
  position: absolute;
  right: 0;
  top: 0px;
  outline: none;
  opacity: 1;
  padding: 0;
  z-index: 4000;
  height: 26px;
  width: 26px;
  border-radius: 3px;
  background-color: $white;
  border: 1px solid $darkGray;
}

.move-audio-button {
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  opacity: 0;
  padding: 0;
  z-index: 4000;
  height: 26px;
  width: 26px;
  border-radius: 3px;
  background-color: $white;
  border: 1px solid $darkGray;
  text-align: center;
}