@import '../../../../styles/colors.scss';
// @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.active-nav-link {
  border: 3px solid black !important;
  border-top: none !important;
  border-bottom: none !important;
  background-color: #f0f0f0;
}

.nav-item {
  button {
    background-color: transparent;
    border: none;
    &:active, &:focus {
      border-bottom: none !important;
    }
  }
}

.nav-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-left: 3px;
  border: 3px solid transparent;
  @media (max-width: 600px) {
    font-size: 0.6em;
  }
  &:focus {
    border: 3px solid $black;
    border-top: none;
    border-bottom: none;
    background-color: #f0f0f0;
  }
  a {
    text-decoration: none;
    color: black;
    font-size: 1em;
    font-weight: normal;
    font-family: 'Lato', sans-serif;
    align-self: center;
  }
}

.nav-link-icon {
  margin: 0px 10px;
}

