@import '../../../styles/colors.scss';

.upload-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.upload-icons-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 30%;
  color: $darkGray;
  p {
    font-size: 0.5em;
  }
  @media(min-width: 576px) {
    p {
      font-size: 0.9em;
    }
  }
}

.upload-icons {
  margin-bottom: 0.3em;
  @media (min-width: 768px) {
    margin-bottom: 2em;
  }
}

.upload-image-icon {
  margin-right: 0.2em;
}

.upload-video-icon {
  margin-left: 0.2em;
}

