.tool-button-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.tool-button-list-item {
  margin: 2px auto;
  width: 100%;
  min-width: inherit;
  @media (min-width: 768px) {
    margin: 6px 0;
    margin-right: 2px;
  }
}

.tools-sider-content {
  margin-top: 0px;
  @media (min-width: 576px) {
    margin-top: 10px;
    width: 100%;
  }
}