@import '../../../styles/colors.scss';

.edit-account-button {
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
}

.edit-account-button-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text-input-field {
  width: 100%;
  margin-right: 2px;
  padding: 0.75rem;
  border: 1px solid $inputGray;
  border-radius: 5px;
  font-size: 1rem;
  &:focus {
    outline: 3px solid $black;
    outline-offset: -0.1cm;
  }
}

.edit-account-container {
  background-color: $white;
  height: 100%;
  width: 100%;
  padding-bottom: 10px;
  margin-top: 30px;
}

.edit-account-content {
  display: flex;
  flex-flow: row nowrap;
  margin: 2em; 
  @media (max-width: 600px) {
    flex-flow: column nowrap;
    margin: 1em;
  }
}

.edit-account-forms-container {
  width: 100%;
  max-width: 800px;
  @media (min-width: 768px) {
    width: 45%;
  }
}

.edit-email-container {
  margin-top: 2em;
}

.edit-password-container {
  margin-bottom: 2em;
}

.input-and-save-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.save-edited-icon {
  margin-right: 10px;
}