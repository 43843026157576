@import '../../../../styles/colors.scss';

.active-template-button {
  border: 2px solid $backgroundGray;
}

.choose-grid-button {
  &:focus {
    outline: none;
    background-color: $backgroundGray;
    color: $white;
  }
}

.grid-buttons-container {
  display: flex;
  flex-flow: row nowrap;
}

.grid-icon {
  width: 112px; 
  height: 80px;
  background-color: $lightGray;
  background-image:
    linear-gradient(90deg, transparent 49.3%, black 49.4%, transparent 50%),
    linear-gradient(to bottom, transparent 49.3%, black 49.4%, transparent 50%);
  background-size: 95%, 95%;
  padding: 0px 5px;
  color: $lightGray; 
  border: 4px solid #808080;
  transform: translate(0%, 10%);
}

.template-image-container {
    display: flex;
    width: 120px;
    min-width: 120px;
    height: 95px;

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    background-color: $white;
    color: $lightGray; 
    }
} 

.template-button {
  background-color: transparent;
  padding: 6px;
  margin-right: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  border: 2px solid transparent;
  &:hover {
    background-color: #efefef;
  }
}

.template-container {
  display: flex;
  flex-flow: row nowrap;  
  align-items: flex-start;
}

.template-icon {
  margin: 0px;
}

.template-text-content {
  p {
    margin-top: 2px;
    margin-bottom: 4px;
    font-size: 0.9em;
  }
  margin-left: 10px;
}