.grid-container {
  width: 100%;
  height: 100%;
  background-image:
    linear-gradient(to right, lightgrey 1px, transparent 1px),
    linear-gradient(to bottom, lightgrey 1px, transparent 1px),
    linear-gradient(-90deg, #afadad 1px, transparent 1px),
    linear-gradient(to top, #afadad 1px, transparent 1px);
  background-size: 
    10px 10px,
    10px 10px, 
    40px 40px,
    40px 40px;
}

.hide-grid {
  //this class is necessary to hide grid
  width: 100%;
  height: 100%;
}

.no-grid-layout {
  width: 100%;
  height: 100%;
}

.page-content {
  width: 100%;
  height: 100%;
}

.upload-container {
  width: 100%;
  height: 100%;
}