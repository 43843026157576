@import '../../styles/colors.scss';

.create-new-book-container {
  height: fit-content;
  width: 100%;
  background-color: $white;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
}

.create-book-content {
  padding: 0;
}