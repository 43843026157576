@import '../../../styles/colors.scss';

.edit-icon {
  margin: 0 0.4em 0 0.2em;
}

.book-info-buttons-container {
  display: flex;
  flex-flow: column-reverse nowrap;  
  width: 170px;
}

.book-icon-button {
  font-size: 0.8em;
  font-weight: normal;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  border: 1px solid #808080;
  background-color: #fff;
  margin-bottom: 1px;
  padding: 1px 4px;
  width: 100%;
  min-width: 90px;
  height: 26px;
  &:hover {
    border: 2px solid $black;
    padding: 0px 3px;
  }
}

.book-info {
  text-align: left;
  padding-left: 2px;
  width: 250px;
  overflow: visible;
}

.book-item {
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 15px;
  width: 270px;
}

.book-link {
  background-color: rgb(239, 239, 239);
  outline: none;
}

.book-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0;
  p {
    margin: 2px 0;
    font-size: 0.9em;
  }
  .link-button {
    font-size: 0.9em;
    text-decoration: none;
    &:focus,
    &:visited,
    &:hover, 
    &:active {
      text-decoration: underline;
    }
  }
}

.book-description {
  font-size: 0.7em;
  text-align: left;
}

.book-title {
  margin: 2px 0px;
  font-size: 1em;
  font-weight: bolder;
  text-align: left;
}

.book-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}

.books-button-container {
  display: flex;
  flex-flow: column nowrap;
  margin: 0px 15px 0px 5px;
  width: 100%;
}

.thumbnail {
  width: 170px;
  height: 107px;
  overflow: hidden; 
  text-align: center;
  border: 1px solid $backgroundGray;
  &:hover .view-book-icon {
    opacity: 0.8;
    background-color: $white;
    border-radius: 2px;
    width: 70px;
    height: 50px;
  }
  &:focus {
    border: none;
  }
}

.thumbnail-button {
  position: relative;
  width: 168px;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}

.view-book-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  padding: 2px;
}