@import '../../styles/colors.scss';
$block_image_padding: 0.7vw;
$block_image_padding_half: calc($block_image_padding / 2);

// Quill specific styles start

.block-textbox > .ql-toolbar {
  position: fixed;
  width: 100%;
  background-color: #efefef;
  border: 1px solid #cccccc;
  z-index: 5000 !important;
}

.quill-editor-custom-class.ql-container.ql-snow {
  border: none;
  outline: none;
	overflow: hidden;
	padding: 0.5em 0 0 0.5em;
  font-family: "Verdana", sans-serif;
  font-size: inherit;
  min-height: 100%;
}

.block-textbox.hide-toolbar > .ql-toolbar {
  display: none !important;
}

.block-textbox .ql-tooltip {
  display: none !important;
}

.ql-editor.ql-blank::before {
  font-style: inherit !important;
}

.block-textbox .ql-editor {
  padding: 0 !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.ql-snow .ql-editor h1 {
  font-size: 250%;
  line-height: 1.5em !important;
  margin-top: 0;
}

.ql-snow .ql-editor ol {
  padding-left: 0;
}

.ql-toolbar.ql-snow .ql-picker.ql-size {
  width: 155px;
}

// Quill specific styles end

.block-tools {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 34px;
  height: auto;
  height: -webkit-fill-available;
  position: absolute;
  top: 0;
  left: -34px;
  opacity: 0;

  button {
    width: 22px;
    height: 22px;
    outline: none;
    padding: 0;
    z-index: 4000;
    border-radius: 3px;
    background-color: #fff;
    border: none;
    margin: 0;
    opacity: 1;
  }

  button:last-of-type {
    margin-top: 22px;
  }

  button.disabled {
    background-color: #c7c7c7;
    opacity: 0.75;

    &:hover {
      cursor: not-allowed;
    }
  }

  .scale-display {
    display: flex;
    justify-content: center;
    margin: 5px 0;
    font-size: 12px;
  }
}

.block-image-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: calc(0.5em + 40px);

  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .block-image-wrapper:first-of-type > img {
    margin-left: 0 !important;
  }

  .block-image-wrapper:last-of-type > img {
    margin-right: 0 !important;
  }

}

.preview-page .block-image-row {
  cursor: default;

  &:active {
    cursor: default;
  }
}

.block-image-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  img.block-image {
    display: flex;
    margin: 0 $block_image_padding_half;
    width: auto;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &:hover > .block-image-tools {
    opacity: 1;
  }

  .block-image-tools {
    position: absolute;
    outline: none;
    opacity: 0;
    padding: 0;
    z-index: 4000;
    height: fit-content;
    width: 22px;
    border: none;
    margin: 0 9px 0 0;
    font-size: inherit;
    top: 0.5em;

    button.delete-block-image-button {
      display: block;
      outline: none;
      opacity: 1;
      padding: 0;
      z-index: 4000;
      height: 22px;
      width: 22px;
      border-radius: 3px;
      background-color: #fff;
      border: none;
      margin: 3px 3px 0 0;
      font-size: 16px;
  }

//   button.edit-block-image-button {
//      display: block;
//     outline: none;
//     opacity: 0;
//     padding: 0;
//     z-index: 4000;
//     height: 22px;
//     width: 22px;
//     border-radius: 3px;
//     background-color: #fff;
//     border: none;
//     margin: 2px 7px 0 0;
//     top: 22px;
//     font-size: 16px;
// }

button.left-block-image-button {
  display: block;
  outline: none;
  opacity: 1;
  padding: 0;
  z-index: 4000;
  height: 22px;
  width: 22px;
  border-radius: 3px;
  background-color: #fff;
  border: none;
  margin: 3px 0 0 0;
  font-size: 16px;
}

button.right-block-image-button {
  display: block;
  outline: none;
  opacity: 1;
  padding: 0;
  z-index: 4000;
  height: 22px;
  width: 22px;
  border-radius: 3px;
  background-color: #fff;
  border: none;
  margin: 3px 0 0 0;
  font-size: 16px;
}

button.left-block-image-button.disabled,
button.right-block-image-button.disabled {
  background-color: #c7c7c7;

  &:hover {
    cursor: not-allowed;
  }
}

}

}

.draggable-editor {
    height: 100% !important;
    width: 100%;
}

.block-add-image-button {
  position: absolute;
  background-color: #05698C;
  border: 1px solid #8B8B8B;
  color: #FFF;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  margin: auto $block_image_padding_half;
  padding: 0.75em;
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
  opacity: 0;

  svg {
    font-size: 16px;
  }

  &:hover {
    border: 1px solid #000;
    outline: 1px solid #000;
  }
}

.block-add-image-button.move-to-right {
  right: -43px;
}

.block-content-container {
  border: 1px solid yellowgreen;
  width: 80px !important;
  height: 80px;
}

.blockbox-wrapper, .thumbtext-wrapper {
	position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
	display: block;
	box-sizing: border-box;
  border: 1px dotted transparent;
}

.blockbox-wrapper:hover {
  border: 1px dotted #737373;
}

.blockbox-wrapper:hover > .block-tools,
.blockbox-wrapper:hover > .block-add-image-button {
  opacity: 1;
}

.blockbox-wrapper .move-item-handle {
  width: 2.5vw !important;
  height: 2.5vw !important;
  transform: none !important;
  bottom: calc(-2.5vw - 3px) !important;
  left: calc(50% - 2.5vw / 2) !important;
}

.block-textbox {
  display: contents;
	box-sizing: border-box;
  outline: none;
  font-family: "Verdana", sans-serif;
	font-size: 1.2em;
  width: fit-content;
  height: fit-content;
  min-height: 2em;
  overflow: hidden;
  &:hover:not(.preview-editor) {
		outline: 1px dotted gray;
    outline-offset: -1px;
	}
}

.block-textbox > .mce-content-body {
  min-width: 10em;
  // min-height: 4em;
}

.blockbox-wrapper, .blockbox-wrapper * {
	user-select: auto !important;
}

.block-textbox p, .block-textbox li, .block-textbox h2 {
	line-height: 1.2em !important;
	margin: 0;
}

.ql-container.ql-snow .ql-editor .ql-size-small,
.textbox.small, .block-textbox small,
.block-textbox .text-smaller {
	font-size: 80%;
  font-family: "Verdana", sans-serif;
	line-height: 0.8em !important;
}

.ql-container.ql-snow .ql-editor h1,
.textbox.large, .block-textbox h1 {
  font-family: "Verdana", sans-serif;
	font-size: 250%;
	line-height: 1.5em !important;
	font-weight: bold;
  margin-top: 0;
}

.ql-container.ql-snow .ql-editor h2,
.block-textbox h2 {
	font-size: 200%;
  font-family: "Verdana", sans-serif;
	line-height: 1.4em !important;
	font-weight: bold;
}

.ql-container.ql-snow .ql-editor p span.medium,
.ql-container.ql-snow .ql-editor .medium,
.ql-container.ql-snow .ql-editor .text-size,
.ql-container.ql-snow .ql-editor p
.block-textbox p span.medium,
.block-textbox .medium,
.block-textbox .text-size,
.block-textbox p {
  font-size: 100%;
	line-height: 1.2em;
  font-family: "Verdana", sans-serif;
}

.ql-container.ql-snow .ql-editor .ql-size-large,
.block-textbox .text-larger, 
.block-textbox .larger {
	font-size: 150%;
	line-height: 120% !important;
  font-family: "Verdana", sans-serif;
}

.ql-container.ql-snow .ql-editor .ql-size-huge,
.block-textbox .large-text,
.block-textbox .large  {
  font-size: 200%;
  line-height: 150% !important;
}

div.tox.tox-tinymce.tox-tinymce--disabled {
  border: none;
}

div.tox.tox-tinymce {
  font-size: inherit;
  height: inherit !important;
  border: none !important;
  outline: none;
}

.tox-tinymce-inline .tox-editor-header {
  z-index: 5000 !important;
}

.mce-content-body {
  outline: none;
  border: none;
	overflow: hidden;
  // padding-top: $block_image_padding_half;
  // padding-right: $block_image_padding;
  // padding-bottom: $block_image_padding;
  // padding-left: $block_image_padding;
  font-family: "Verdana", sans-serif;
  font-size: inherit;
  min-height: 100%;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  padding-left: 10px;
}

.block-textbox .quill-editor-custom-class p,
.block-textbox .quill-editor-custom-class h1,
.block-textbox .quill-editor-custom-class h2,
.block-textbox .quill-editor-custom-class span,
.block-textbox .quill-editor-custom-class small,
.block-textbox .quill-editor-custom-class strong
.block-textbox .mce-content-body p,
.block-textbox .mce-content-body h1,
.block-textbox .mce-content-body h2,
.block-textbox .mce-content-body span,
.block-textbox .mce-content-body small,
.block-textbox .mce-content-body strong {
  font-family: "Verdana", sans-serif;
  white-space: pre-wrap;
}

.resizable-handle {
  position: absolute;
  transition: .5s ease;
  bottom: 11px;
  right: 14px;
  opacity: 0;
  background-color: $white;
  transform: rotate(0.25turn);
  margin: 0;
  border: none;
}

.textbox:hover {
  z-index: 101;
}

.tox-collection__item-label span,
.tox-collection__item-label body {
  background-color: $white !important;
}

.tox .tox-toolbar__group {
  padding: 0px !important;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
  background-color: #efefef !important;
}

.tox .tox-tbtn--select {
  padding: 0px 1px !important;
}

.tox-collection__item-label {
  background-color: $white !important;
}

.tox .tox-edit-area__iframe {
  background-color: initial !important;
}

// Piilottaa hover efektit esikatselusta
.preview-remove-effects {
  &:hover {
    border: 1px dotted transparent !important;
  }
}

// Old styles

.block-textbox .text-noto-sans {
	font-family: "Noto Sans";
}
.block-textbox .text-comic-neue {
	font-family: "Comic Neue";
}
.block-textbox .text-verdana {
	font-family: "Verdana";
}
.block-textbox .text-arial {
	font-family: "Arial","Helvetica";
}
.block-textbox .text-georgia {
	font-family: "Georgia";
}
.block-textbox .text-times-new-roman {
	font-family: "Times New Roman";
}

.red { color: #c11;}
.green { color: #1c1;}
.blue { color: #11c;}
.black { color: #000;}
.gray { color: #888;}
.white { color: #fff;}

.bg-red { background-color: #c11;}
.bg-green { background-color: #1c1;}
.bg-blue { background-color: #11c;}
.bg-black { background-color: #000;}
.bg-gray { background-color: #888;}
.bg-white { background-color: #fff;}
.bg-transparent { background-color: none; }
