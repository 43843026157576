$backgroundGray: #8B8B8B;
$darkGray: #737373;
$gridGray: #666;
$buttonGray: #EFEFEF;
$inputGray: #4B4B4B;
$black: #000;
$orange: #E0790B;
$lightGray: #f7f7f7;
$darkGreen: #388360;
$darkRed: #D00B0B;
$lightRed: #FFF5F5;
$lightblue: #fafbfc; 
$slateblue:  #05698C;
$darkblue: #21589C;
$white: #fff;

$opikeWhite: #ffffff;
$opikeBlack: #000000;
$opikeGreen: #008577;
$opikeDarkGreen: #00584f;
$opikeYellow: #ffcc3f;
$opikeDarkYellow: #f0bf37;