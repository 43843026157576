@import '../../../styles/colors.scss';

.choose-image-button {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 161px;
  padding-bottom: 15px;
  &:focus {
    outline: 3px solid black;
    outline-offset: -0.1cm;
  }
}

.flex-button-container {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'space-between';
}

.image-result {
  max-height: 177px;
  max-width: 161px;
}

.no-results-text {
  margin-top: 30px;
}

.search-result-container {
  margin-top: 10px;
}

.search-result-text { 
  width: 100%; 
  overflow: hidden;
  &:hover {
    overflow: visible;
  }
}