@import '../../styles/colors.scss';

.text-input-class {
  padding: 0.75rem;
  margin-right: 2px;
  border-radius: 5px;
  border: 1px solid gray;
  width: 100%;
  font-size: 1rem;
  outline: none;
}

.text-input-container {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  width: 100%;
}

.input-label-class {
  font-size: 0.9em;
  margin-top: 10px;
}

.text-label-class {
  margin-bottom: 2px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $darkGray;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $darkGray;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $darkGray;
}