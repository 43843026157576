@import '../../../styles/colors.scss';

.copy-book-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.copy-book-form-label {
  font-size: 1em;
  margin-top: 30px;
}

.shared-book-icon-button {
  width: 100%;
  height: 34px;
}

