@import '../../styles/colors.scss';

// Quill specific styles start

.textbox > .ql-toolbar {
  // position: fixed;
  width: 100%;
  background-color: #efefef;
  border: 1px solid #cccccc;
  z-index: 5000 !important;
}

.quill-editor-custom-class.ql-container.ql-snow {
  border: none;
  outline: none;
	overflow: hidden;
	padding: 0.5em 0 0 0.5em;
  font-family: "Verdana", sans-serif;
  font-size: inherit;
  min-height: 100%;
}

.textbox.hide-toolbar > .ql-toolbar {
  display: none !important;
}

.textbox .ql-tooltip {
  display: none !important;
}

.ql-editor.ql-blank::before {
  font-style: inherit !important;
}

.textbox .ql-editor {
  padding: 0 !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.ql-snow .ql-editor h1 {
  font-size: 250%;
  line-height: 1.5em !important;
  margin-top: 0;
}

.ql-snow .ql-editor ol {
  padding-left: 0;

  li.ql-align-center {
    padding-left: 0.5em;
  }
}

.ql-toolbar.ql-snow .ql-picker.ql-size {
  width: 155px;
}

// Quill specific styles end

.draggable-editor {
    height: 100% !important;
    width: 100%;
}

.textbox {
	box-sizing: border-box;
  outline: none;
  font-family: "Verdana", sans-serif;
	font-size: 1.2em;
  width: 100% !important;
  height: 100% !important;
  overflow: visible;
  &:hover:not(.preview-editor) {
		outline: 1px dotted gray;
    outline-offset: -1px;
	}
}

.textbox-wrapper, .thumbtext-wrapper {
	position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
	display: block;
	box-sizing: content-box;
}

.textbox-wrapper, .textbox-wrapper * {
	user-select: auto !important;
}

.textbox p, .textbox li, .textbox h2 {
	line-height: 1.2em !important;
	margin: 0 !important;
}

.ql-container.ql-snow .ql-editor .ql-size-small,
.textbox.small, .textbox small,
.textbox .text-smaller {
	font-size: 80%;
  font-family: "Verdana", sans-serif;
	line-height: 0.8em !important;
}

.ql-container.ql-snow .ql-editor h1,
.textbox.large, .textbox h1 {
  font-family: "Verdana", sans-serif;
	font-size: 250%;
	line-height: 1.5em !important;
	font-weight: bold;
  margin-top: 0;
}

.ql-container.ql-snow .ql-editor h2,
.textbox h2 {
	font-size: 200%;
  font-family: "Verdana", sans-serif;
	line-height: 1.4em !important;
	font-weight: bold;
}

.ql-container.ql-snow .ql-editor p span.medium,
.ql-container.ql-snow .ql-editor .medium,
.ql-container.ql-snow .ql-editor .text-size,
.ql-container.ql-snow .ql-editor p
.textbox p span.medium,
.textbox .medium,
.textbox .text-size,
.textbox p {
  font-size: 100% !important;
	line-height: 1.2em !important;
  font-family: "Verdana", sans-serif;
}

.ql-container.ql-snow .ql-editor .ql-size-large,
.textbox .text-larger,
.textbox .larger {
	font-size: 150%;
	line-height: 120% !important;
  font-family: "Verdana", sans-serif;
}

.ql-container.ql-snow .ql-editor .ql-size-huge,
.textbox .large-text,
.textbox .large  {
  font-size: 200%;
  line-height: 150% !important;
}

div.tox.tox-tinymce.tox-tinymce--disabled {
  border: none;
}

div.tox.tox-tinymce {
  font-size: inherit;
  height: inherit !important;
  border: none !important;
  outline: none;
}

.tox-tinymce-inline .tox-editor-header {
  z-index: 5000 !important;
}

.mce-content-body {
  outline: none;
  border: none;
	overflow: hidden;
	padding: 0.5em;
  font-family: "Verdana", sans-serif;
  font-size: inherit;
  min-height: 100%;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  padding-left: 10px;
}

.textbox-wrapper .quill-editor-custom-class p,
.textbox-wrapper .quill-editor-custom-class h1,
.textbox-wrapper .quill-editor-custom-class h2,
.textbox-wrapper .quill-editor-custom-class span,
.textbox-wrapper .quill-editor-custom-class small,
.textbox-wrapper .quill-editor-custom-class strong
.mce-content-body p,
.mce-content-body h1,
.mce-content-body h2,
.mce-content-body span,
.mce-content-body small,
.mce-content-body strong {
  font-family: "Verdana", sans-serif;
  white-space: pre-wrap;
}

.resizable-handle {
  position: absolute;
  transition: .5s ease;
  bottom: 11px;
  right: 14px;
  opacity: 0;
  background-color: $white;
  transform: rotate(0.25turn);
  margin: 0;
  border: none;
}

.textbox:hover {
  z-index: 101;
}

.tox-collection__item-label span,
.tox-collection__item-label body {
  background-color: $white !important;
}

.tox .tox-toolbar__group {
  padding: 0px !important;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
  background-color: #efefef !important;
}

.tox .tox-tbtn--select {
  padding: 0px 1px !important;
}

.tox-collection__item-label {
  background-color: $white !important;
}

.tox .tox-edit-area__iframe {
  background-color: initial !important;
}

// Old styles

.textbox .text-noto-sans {
	font-family: "Noto Sans";
}
.textbox .text-comic-neue {
	font-family: "Comic Neue";
}
.textbox .text-verdana {
	font-family: "Verdana";
}
.textbox .text-arial {
	font-family: "Arial","Helvetica";
}
.textbox .text-georgia {
	font-family: "Georgia";
}
.textbox .text-times-new-roman {
	font-family: "Times New Roman";
}

.red { color: #c11;}
.green { color: #1c1;}
.blue { color: #11c;}
.black { color: #000;}
.gray { color: #888;}
.white { color: #fff;}

.bg-red { background-color: #c11;}
.bg-green { background-color: #1c1;}
.bg-blue { background-color: #11c;}
.bg-black { background-color: #000;}
.bg-gray { background-color: #888;}
.bg-white { background-color: #fff;}
.bg-transparent { background-color: none; }
