@import '../../../styles/colors.scss';

.search-button {
  margin-left: 40px;
  padding: 8px 15px;
  background-color: $slateblue;
  border: 2px solid $slateblue;
  color: $white;
  border-radius: 0.25rem;
  &:hover {
    color: black;
    border: 2px solid $slateblue;
    background-color: $lightblue;
  }
}

.search-buttons-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1%;
}

.search-input-and-buttons-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0 10px;
  button {
    margin-right: auto;
  }
  @media (min-width: 425px) {
    flex-flow: row nowrap;
  }
}

.search-container {
  @media (max-width: 600px) {
    width: 80vw;
  }
}

h3.search-results-header {
  margin: 15px auto;
  text-align: center;
}
