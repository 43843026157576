@import '../../../styles/colors.scss';

#edit-book-details-form {
  width: 100%;
  .text-input-container {
    width: 100%;
  }
}
.edit-book-input {
  width: 100%;
  height: 40px;
}

.edit-header-icon {
  margin: 0 1vw;
  font-size: 200%;
}

.edit-book-field-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.edit-book-title {
  margin: 20px 0px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-right {
  margin-left: 2em;
}

.new-page-header-container {
  background-color: $buttonGray;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 6vw;
  width: 100%;
  padding: 1vw;
  font-size: 2vw;
  &:hover {
    z-index: 10000;
  }
}


.new-page-header-icons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}


.page-header-icon-button {
  background-color: $white;
  border: 1px solid $backgroundGray;
  color: $black;
  border-radius: 5px;
  width: fit-content;
  margin: 0;
  margin-right: 1vw;
  padding: 0.2em 1.5em;
  font-size: 0.8vw;
  text-decoration: none;
  &:focus, &:hover {
    border: 1px solid $black;
    outline: 1px solid $black;
  }
  .icon-button-content {
    padding: 0;
  }
  &:hover {
    text-decoration: none;
  }
  @media (min-width: 576px) {
    padding: 1vw;
    height: 4vw;
    margin-right: 0.2vw;
    &:focus, &:hover {
      border: 1px solid $black;
      outline: 1px solid $black;
    }
  }
  @media (min-width: 1024px) {
    margin-right: 0.5vw;
    font-size: 1vw;
  }

}

.inactive-button {
  color: $darkGray;
  background-color: #fafafa;
  border: 1px solid $backgroundGray;
  &:hover,
  &:active,
  &:focus {
    cursor: default; 
    outline: none !important;
    border: 1px solid $backgroundGray;
  }
}

.page-header-icon {
  margin: 2px auto;
  font-size: 3vw;
  @media (min-width: 576px) {
    margin: 0px 4px 0px 0px;
    font-size: 2vw;
  }
  @media (min-width: 768px) {
    margin-right: 10px;
    font-size: 1.5vw;
  }
}

.page-header-icon-text {
  display: none;
  font-size: 1.5vw;
  @media (min-width: 576px) {
    display: block;
  }
}

.save-icon-button {
  margin-left: 2vw;
  width: 30px;
  height: 30px;
}

.book-header-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.book-details-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3vw;
  p {
    margin: 0px;
  }
}