@import '../../../styles/colors.scss';

.delete-item-button {
  position: absolute;
  right: 2px;
  top: 2px;
  outline: none;
  opacity: 0;
  padding: 0;
  z-index: 4000;
  height: 26px;
  width: 26px;
  border-radius: 3px;
  background-color: $white;
  border: none;
  margin: 0;
}

.delete-image-button {
  position: absolute;
  right: 0;
  top: 0px;
  outline: none;
  opacity: 0;
  padding: 0;
  z-index: 4000;
  height: 26px;
  width: 26px;
  border-radius: 3px;
  background-color: $white;
  border: 1px solid backgroundGray;
}

.edit-image-button {
  position: absolute;
  left: 2px;
  top: 2px;
  outline: none;
  opacity: 0;
  padding: 0;
  z-index: 400;
  min-height: 26px;
  min-width: 26px;
  border-radius: 3px;
  background-color: $white;
  border: none;
  margin: 0;
}

.image-container {
  width: 100%;
  height: 100%;
}

.uploaded-resizable-image-content {
  height: 100%;
  max-width: 100%;
  transition: .5s ease;
  opacity: 1;
  z-index: 1;
}