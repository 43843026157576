@import '../../styles/colors.scss';

.color-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 280px;
  background-color: $white;
  padding: 10px;
  border: 1px solid gray;
  box-shadow: 1px 1px gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  outline: none;
}

.color-palette-buttons-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.color-picker-content {
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.color-picker-header {
  margin: 0 10px 0 0;
  font-size: 1.7em;
  text-align: left;
}

.sketch-picker {
  box-shadow: unset !important;
  width: 100% !important;
}

.swatch-container {
  height: 28px;
  padding: 3px;
  background: #fff;
  border: 1px solid black;
  border-radius: 1px;
  display: inline-block;
  margin-left: 10px;
}
