@import '../../styles/colors.scss';

.instructions-icon {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.instructions-sider-content {
  height: 100%;
  padding: 2px;
  background-color: $white;
  height: inherit;
  min-height: 100%;
  font-size: 0.8em;
  margin: 0;
  @media (min-width: 576px) {
    font-size: 0.9em;
    padding: 1em 0.5em;
  }
  @media (min-width: 1024px) {
    font-size: 1em;
  }
}

.instructions-sider-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.instructions-sider-text {
  font-size: 0.6em;
  @media (min-width: 768px) {
    font-size: 1em;
  }
}

.instructions-sider-title {
  text-transform: uppercase;
  font-size: 1.7vw;
  margin: 0;
  margin-bottom: 2px;
  @media (min-width: 768px) {
    margin-left: 3px;
    margin-top: 0;
  }
  @media (min-width: 1024px) {
    margin-left: 10px;
    margin-bottom: 1vw;
  }
}

.sider-instructions-container {
  padding: 2px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: $white;
  outline: none;
  min-height: calc(63vw * 0.625 + 6vw);
  height: fit-content;
  max-height: 1024px;
}

