@import '../../styles/colors.scss';

.add-alt-preview-image-container {
  height: auto;
  width: 100%;
  padding: 0.5em 0;
  text-align: center;
}

.choose-image-modal-container {
  z-index: 1001;
  background-color: $white;
}

.header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  h1 {
    width: 100%;
    margin-right: 0.5em;
  }
}

.image-preview-alternative-text {
  color: $darkGray;
  font-weight: bold;
  padding: 2em;
}

.nav-tabs .nav-link {
  border: 2px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: $darkblue;
}

.nav-tabs .nav-link.active {
  border-color: $darkGray $darkGray #fff;
}

.image-text-input-container {
  small {
    text-align: left;
    display: block;
    margin: 0.5em 0 2em;
  }
  h3 {
    margin: 0;
  }
  .validation-error-text {
    height: 1em;
  }
}

.image-text-input-container {
  label {
    text-align: left;
    display: block;
    margin-top: 10px;
  }
}

div.search-buttons-container .dropdown-menu > form > .form-control {
  border: none;
}
