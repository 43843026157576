@import '../../../styles/colors.scss';

.tool-button {
  background-color: transparent;
  border: 1px solid darkgray;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0.8vw 1vw;
  border-radius: 0.2em;
  font-size: 1.5vw;
  text-align: left;
  width: 100%;
  @media (min-width: 425px) {
    justify-content: flex-start;
  }
  @media (min-width: 576px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  
  .icon-button-content {
    @media (max-width: 576px) {
      justify-content: center;
    }
  }
  &:hover {
    border: 1px solid $black;
    outline: 1px solid $black;
  }
}

.tool-button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tool-button-icon {
  font-size: 3vw;
  margin: 0;
  text-align: center;
  @media (min-width: 425px) {
    margin-right: 4px;
    font-size: 2vw;
  }
  @media (min-width: 768px) {
    margin-right: 6px;
  }
}

.tool-button-icon-custom {
  width: 1.155em;
  margin: 0;
  text-align: center;
  @media (min-width: 425px) {
    margin-right: 4px;
  }
  @media (min-width: 768px) {
    margin-right: 6px;
  }
}

.tool-button-text {
  font-size: 1em;
  display: none;
  @media (min-width: 425px) {
    display: block;
  }
}