.file-upload-container {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  p {
    text-align: left;
    margin-top: 15px;
  }
  .spinner-content {
    padding-top: 20px;
  }
}

.margin-top-container {
  margin-top: 2em;
}

.progressbar {
  width: 100%;
  text-align: left;
  height: 24px;
  border: 1px solid gray;
  border-radius: 5%;
  position: relative;
}

.progressbar-block {
  background-color: lightgreen;
  position: absolute;
  top: 0;
  left: 0;
}