@import '../../../styles/colors.scss';

.add-image-button {
  background-color: $lightGray;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 1.8vw;
  &:hover {
    border-color: $black;
  }
}

.add-image-button-text {
  margin-top: 5px;
  font-size: 0.5em;
  @media(min-width: 768px) {
    font-size: 0.7em;
  }
  @media(min-width: 1024px) {
    font-size:  1em;
  }
}

.add-image-icon {
  font-size: 1em;
}

.edit-cell-container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
}

.edit-cell-content {
  width: 300px;
}

.grid-layout {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: transparent;
  padding: 0 !important;
  box-sizing: border-box;
}

.cell {
  border-right: 1px solid $gridGray;
  border-bottom: 1px solid $gridGray;
}

.cell-no-right-border {
  border-right: none;
}

.cell-no-bottom-border {
  border-bottom: none;
}

/*.grid-layout-page-content-2x2 {
  @extend .grid-layout;
  background-image:
    linear-gradient(-90deg, transparent 49.8%, $gridGray 49.9%, transparent 50%),
    linear-gradient(to top, transparent 49.7%, $gridGray 49.8%, transparent 50%);
}

.grid-layout-page-content-3x2 {
  @extend .grid-layout;
  background-image:
  linear-gradient(-90deg, transparent 33.2%, $gridGray 33.3%, transparent 33.4%),
  linear-gradient(-90deg, transparent 66.5%, $gridGray 66.6%, transparent 66.7%),
  linear-gradient(to top, transparent 49.7%, $gridGray 49.8%, transparent 50%);
  background-size: 
    100%,
    100%;
}

.grid-layout-page-content-4x3 {
  @extend .grid-layout;
  background-image:
    linear-gradient(to right, transparent 24.9%, $gridGray 25%, transparent 25.1%),
    linear-gradient(to right, transparent 49.9%, $gridGray 50%, transparent 50.1%),
    linear-gradient(to right, transparent 74.9%, $gridGray 75%, transparent 75.1%),
    linear-gradient(to top, transparent 33.2%, $gridGray 33.3%, transparent 33.4%),
    linear-gradient(to top, transparent 66.5%, $gridGray 66.6%, transparent 66.7%),;
  background-size: 
    100%,
    100%;
} */

.grid-item-container {
  position: relative;
  &:hover .arrow-button,
  &:focus .arrow-button {
    opacity: 0.8;
  }
  p {
    margin: 0 auto;
  }
}

.arrow-button {
  position: absolute;
  top: 50%;
  background-color: white;
  width: 30px;
  height: 30px;
  opacity: 0;
  border: 1px solid gray;
  z-index: 1000;
  &:hover, &:focus {
    background-color: #ddd;
  }
}

.audio-cell-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: $lightGray;
  color: $gridGray;
  border: none;
  border-radius: 5px;
  padding: 1px;
  height: 20px;
  width: 20px !important;
}

.cell-image-caption {
  width: 100%;
  height: 12%;
  font-size: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.move-left-arrow-button {
  @extend .arrow-button;
  left: 1px;
}

.move-right-arrow-button {
  @extend .arrow-button;
  right: 0;
}

.preview-audio-button {
  padding: 5%;
  border: 1px solid gray;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  margin: 0 auto 1em;
}

.preview-cell-audio-button {
  position: absolute;
  left: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: inherit;
  height: inherit;
  background: transparent;
  border: none;
}
