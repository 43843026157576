@import '../../../styles/colors.scss';

.info-icon {
  margin-right: 10px;
  font-size: 3vw;
  margin-left: 5px;
  @media (min-width: 576px) {
    font-size: 2vw;
  }
  @media (min-width: 1440px) {
    font-size: 1em;
  }
}

.info-link-content {
  color: $black;
  margin-right: 2px;
  font-size: 0.7em;
  line-height: initial;
  padding: 4px;
  display: flex;
  align-items: center;
  @media(min-width: 425px) {
    margin-right: 18px;
    font-size: 1em;
  }
  &:hover {
    color: $darkblue;
  }
  svg {
    color: $slateblue;
  }
}

.info-text {
  margin: 0;
  font-size: 0.7em;
  padding: 0;
  color: $black;
  @media (min-width: 375px) {
    font-size: 0.9em;
  }
  @media (min-width: 768px) {
    font-size: 1em;
  }
  &:hover {
    color: $slateblue;
  }
}

.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0;
}

.papunet-logo {
  color: $orange;
  font-size: 1.4em;
  font-family: Verdana, sans-serif;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  outline: none;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: black;
  }
}

.papuri-logo {
  color: $black;
  font-size: 0.8em;
  font-weight: 500;
  font-family: Verdana, sans-serif;  
  margin: 0;
  margin-top: 2px;
  @media (min-width: 768px) {
    font-size: 1.1em;
  }
  &:hover {
    color: $black;
  }
}

.top-nav-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  height: 2vw;
  min-height: 30px;
  align-items: center;
  background-color: $white;
  a {
    text-decoration: none;
  }
}