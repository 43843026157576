.logout-button {
  margin: 10px;
}

.logout-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em 0 1em;
  h2 {
    margin-bottom: 1em;
  }
}