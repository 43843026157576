@import '../../styles/colors.scss';
$block_image_padding: 0.7vw;
$block_image_padding_half: calc($block_image_padding / 2);

// Quill specific styles start

.question-textbox > .ql-toolbar,
.answer-textbox > .ql-toolbar {
  position: absolute;
  top: -42px;
  width: max-content;
  background-color: #efefef;
  border: 1px solid #cccccc;
  z-index: 5000 !important;
  height: 42px;
  white-space: nowrap;
}

// Peukku painikkeet
.answer-textbox > .ql-toolbar {
  padding: 5px !important;

  button {
    width: 30px !important;
    height: 30px !important;
    border-radius: 4px;
    padding: 1px 2px !important;
    display: flex !important;

    svg {
      float: none !important;
      width: 22px !important;
      height: 22px !important;
    }
  }

  button#ql-custom-button-correct-answer {
    background: #7ed321 !important;
    opacity: 0.6 !important;
    border: 3px solid transparent !important;
  }

  button#ql-custom-button-correct-answer.active-answer {
    opacity: 1 !important;
    border-color: #64a91b !important;
  }

  button#ql-custom-button-false-answer {
    background: #dd1f1f !important;
    opacity: 0.6 !important;
    border: 3px solid transparent !important;
  }

  button#ql-custom-button-false-answer.active-answer {
    opacity: 1 !important;
    border-color: #b01919 !important;
  }
}

.question-textbox > .ql-toolbar.ql-snow .ql-formats,
.answer-textbox > .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
  vertical-align: top !important;
}

.quill-editor-custom-class.ql-container.ql-snow {
  border: none;
  outline: none;
	overflow: hidden;
	padding: 0.5em 0 0 0.5em;
  font-family: "Verdana", sans-serif;
  font-size: inherit;
  min-height: 100%;
}

.question-textbox.hide-toolbar > .ql-toolbar,
.answer-textbox.hide-toolbar > .ql-toolbar {
  display: none !important;
}

.question-textbox .ql-tooltip,
.answer-textbox .ql-tooltip {
  display: none !important;
}

.ql-editor.ql-blank::before {
  font-style: inherit !important;
}

.question-textbox .ql-editor,
.answer-textbox .ql-editor {
  padding: 0 !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.ql-snow .ql-editor h1 {
  font-size: 250%;
  line-height: 1.5em !important;
  margin-top: 0;
}

.ql-snow .ql-editor ol {
  padding-left: 0;
}

.ql-toolbar.ql-snow .ql-picker.ql-size {
  width: 155px;
}

// Quill specific styles end

.question-tools {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 34px;
  height: auto;
  height: -webkit-fill-available;
  position: absolute;
  top: 0;
  left: -34px;
  opacity: 0;

  button {
    width: 22px;
    height: 22px;
    outline: none;
    padding: 0;
    z-index: 4000;
    border-radius: 3px;
    background-color: #fff;
    border: none;
    margin: 0;
    opacity: 1;
  }

  button:last-of-type {
    margin-top: 22px;
  }

  button.disabled {
    background-color: #c7c7c7;
    opacity: 0.75;

    &:hover {
      cursor: not-allowed;
    }
  }

  .scale-display {
    display: flex;
    justify-content: center;
    margin: 5px 0;
    font-size: 12px;
  }
}

.draggable-editor {
  height: 100% !important;
  width: 100%;
}

.add-answer-button {
  position: absolute;
  background-color: #05698c;
  border: 1px solid #8b8b8b;
  color: #fff;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  margin: $block_image_padding 0 0 0;
  padding: 0.75em;
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
  opacity: 0;

  svg {
    font-size: 16px;
  }

  &:hover {
    border: 1px solid #000;
    outline: 1px solid #000;
  }
}

.block-content-container {
  border: 1px solid yellowgreen;
  width: 80px !important;
  height: 80px;
}

.question-wrapper,
.thumbtext-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  display: block;
  box-sizing: border-box;
  border: 1px dotted transparent;
}

.question-wrapper:hover {
  border: 1px dotted #737373;
}

.question-wrapper:hover > .question-tools,
.question-wrapper:hover > .add-answer-button {
  opacity: 1;
}

.question-wrapper .move-item-handle {
  width: 2.5vw !important;
  height: 2.5vw !important;
  transform: none !important;
  top: calc(-2.5vw - 3px) !important;
  left: calc(50% - 2.5vw / 2) !important;
}

.question-textbox {
  display: contents;
  box-sizing: border-box;
  outline: none;
  font-family: 'Verdana', sans-serif;
  font-size: 1.2em;
  width: fit-content;
  height: fit-content;
  min-height: 2em;
  overflow: hidden;
  &:hover:not(.preview-editor) {
    outline: 1px dotted gray;
    outline-offset: -1px;
  }
}

.question-textbox > .quill-editor-custom-class,
.question-textbox > .mce-content-body {
  min-width: 15em;
  min-height: 2em;
}

.question-wrapper,
.question-wrapper * {
  user-select: auto !important;
}

.answer-textbox:last-of-type {
  margin-bottom: 0.7em !important;
}

.question-textbox p,
.question-textbox li,
.question-textbox h2 {
  line-height: 1.2em !important;
  margin: 0;
}

.answer-textbox {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 0.75em 0.5em;
  border-radius: 5px;
  outline: none;
  font-family: 'Verdana', sans-serif;
  font-size: 1.2em;
  width: calc(100% - 1em);
  height: fit-content;
  min-height: 2em;
  overflow: visible;
  &:hover:not(.preview-editor) {
    outline: 1px dotted gray;
    outline-offset: -1px;
  }
}

.answer-textbox > .quill-editor-custom-class,
.answer-textbox > .mce-content-body {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100% !important;
  min-width: 10em;
  margin: 0;
  padding: 0.5em;
  border: 1px solid;
  border-radius: 5px;
  background-color: inherit;
  // min-height: 4em;
}

.answer-textbox > .delete-answer-button {
  display: block;
  position: absolute;
  font-size: inherit;
  margin: 0;
  padding: 0;
  width: 1.1em;
  height: 1.1em;
  outline: none;
  z-index: 4000;
  border-radius: 3px;
  background-color: #fff;
  border: none;
  left: calc(100% - 1.1em - 2px);
  top: 2px;
  opacity: 0;
}

.answer-textbox > *:hover {
  .delete-answer-button {
    opacity: 1;
  }
}

.answer-textbox li,
.answer-textbox h2 {
  line-height: 1.2em !important;
  margin: 0;
}

.answer-textbox p {
  line-height: 1.22em !important;
  margin: 0;
  margin-right: 1.75em;
}

// .tox-toolbar__group .tox-tbtn:first-child:nth-last-child(2) {
//   background: #7ed321 !important;
//   opacity: 0.6 !important;
// }

// .tox-toolbar__group .tox-tbtn--enabled:first-child:nth-last-child(2) {
//   opacity: 1 !important;
//   border: 3px solid #64a91b !important;
// }

// .tox-toolbar__group .tox-tbtn:last-child:nth-child(2) {
//   background: #dd1f1f !important;
//   opacity: 0.6 !important;
// }

// .tox-toolbar__group .tox-tbtn--enabled:last-child:nth-child(2) {
//   opacity: 1 !important;
//   border: 3px solid #b01919 !important;
// }

// .tox-toolbar__group .tox-tbtn:not(.tox-tbtn--enabled):active:focus:hover,
// .tox-toolbar__group .tox-tbtn:not(.tox-tbtn--enabled):last-of-type:active:focus,
// .tox-toolbar__group .tox-tbtn:not(.tox-tbtn--enabled):last-of-type:active,
// .tox-toolbar__group .tox-tbtn:not(.tox-tbtn--enabled):last-of-type {
//   background: transparent !important;
// }

// .tox-toolbar__group .tox-tbtn:not(.tox-tbtn--enabled):last-of-type:hover {
//   background: #dee0e2 !important;
// }

div.tox.tox-tinymce.tox-tinymce--disabled {
  border: none;
}

div.tox.tox-tinymce {
  font-size: inherit;
  height: inherit !important;
  border: none !important;
  outline: none;
}

.tox-tinymce-inline .tox-editor-header {
  z-index: 5000 !important;
}

.mce-content-body {
  outline: none;
  border: none;
  overflow: hidden;
  font-family: 'Verdana', sans-serif;
  font-size: inherit;
  min-height: 100%;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  padding-left: 10px;
}

.question-textbox .quill-editor-custom-class p,
.question-textbox .quill-editor-custom-class h1,
.question-textbox .quill-editor-custom-class h2,
.question-textbox .quill-editor-custom-class span,
.question-textbox .quill-editor-custom-class small,
.question-textbox .quill-editor-custom-class strong
.question-textbox .mce-content-body p,
.question-textbox .mce-content-body h1,
.question-textbox .mce-content-body h2,
.question-textbox .mce-content-body span,
.question-textbox .mce-content-body small,
.question-textbox .mce-content-body strong {
  font-family: 'Verdana', sans-serif;
  white-space: pre-wrap;
}

.answer-textbox .quill-editor-custom-class p,
.answer-textbox .quill-editor-custom-class h1,
.answer-textbox .quill-editor-custom-class h2,
.answer-textbox .quill-editor-custom-class span,
.answer-textbox .quill-editor-custom-class small,
.answer-textbox .quill-editor-custom-class strong
.answer-textbox .mce-content-body p,
.answer-textbox .mce-content-body h1,
.answer-textbox .mce-content-body h2,
.answer-textbox .mce-content-body span,
.answer-textbox .mce-content-body small,
.answer-textbox .mce-content-body strong {
  font-family: 'Verdana', sans-serif;
  white-space: pre-wrap;
}

.resizable-handle {
  position: absolute;
  transition: 0.5s ease;
  bottom: 11px;
  right: 14px;
  opacity: 0;
  background-color: $white;
  transform: rotate(0.25turn);
  margin: 0;
  border: none;
}

.textbox:hover {
  z-index: 101;
}

.tox-collection__item-label span,
.tox-collection__item-label body {
  background-color: $white !important;
}

.tox .tox-toolbar__group {
  padding: 0px !important;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-color: #efefef !important;
}

.tox .tox-tbtn--select {
  padding: 0px 1px !important;
}

.tox-collection__item-label {
  background-color: $white !important;
}

.tox .tox-edit-area__iframe {
  background-color: initial !important;
}

// Piilottaa hover efektit esikatselusta
.preview-remove-effects {
  &:hover {
    border: 1px dotted transparent !important;
  }
}

// Old styles

.question-textbox .text-noto-sans {
  font-family: 'Noto Sans';
}
.question-textbox .text-comic-neue {
  font-family: 'Comic Neue';
}
.question-textbox .text-verdana {
  font-family: 'Verdana';
}
.question-textbox .text-arial {
  font-family: 'Arial', 'Helvetica';
}
.question-textbox .text-georgia {
  font-family: 'Georgia';
}
.question-textbox .text-times-new-roman {
  font-family: 'Times New Roman';
}

.red {
  color: #c11;
}
.green {
  color: #1c1;
}
.blue {
  color: #11c;
}
.black {
  color: #000;
}
.gray {
  color: #888;
}
.white {
  color: #fff;
}

.bg-red {
  background-color: #c11;
}
.bg-green {
  background-color: #1c1;
}
.bg-blue {
  background-color: #11c;
}
.bg-black {
  background-color: #000;
}
.bg-gray {
  background-color: #888;
}
.bg-white {
  background-color: #fff;
}
.bg-transparent {
  background-color: none;
}
