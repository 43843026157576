@import '../../../styles/colors.scss';

.frontpage-image {
  border: 1px solid black;
  margin-bottom: 3%;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: $lightGray;
  padding: 0.5em 1.5em 1em;
  border-radius: 5px;
  margin: 1em 0;
}

.login-form-content {
  &:h1 {
    margin: 30px 0;
  }
}

#login-container {
  width: 100%;
  background-color: $white;
  margin: 8px auto;
  padding-bottom: 3%;
  height: 100%;
  min-height: 70vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: space-around;
  font-size: 0.9em;
  @media (min-width: 768px) {
    flex-flow: row nowrap;
  }
}

.info-link {
  font-size: 1em;
  &:focus {
    outline: 2px solid $black;
    outline-offset: 0.5em;
  }
  &:hover, &:active {
    color: $darkblue;
  }
}

.frontpage-left-content {
  margin: 0 3% 10% 3%;
  width: 100%;
  max-width: 600px;
  background-color: $white;
}

.frontpage-right-content {
  width: 100%;
  margin: 3%;
  @media (min-width: 768px) {
    margin-left: 8%;
  }
}